import { Component, OnInit } from '@angular/core';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  loggedIn: boolean = null;

  constructor(public auth: AuthService) {

    this.auth.isLoggedIn().then((result: boolean) => {
      this.loggedIn = result;
    });

  }

  ngOnInit() {

  }

}
