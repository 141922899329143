import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import {
  Auth,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithRedirect,
  getRedirectResult,
  signOut,
  user
} from '@angular/fire/auth';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  uid: string = null;
  user$: Observable<any>;

  constructor(
    private auth: Auth,
    private router: Router,
    private ngZone: NgZone,
    public platform: Platform
  ) {

    this.user$ = user(this.auth);

    onAuthStateChanged(this.auth, (result) => {
      if (result) {
        this.uid = result.uid;
      } else {
        this.uid = null;
      }
    });

  }

  async isLoggedIn(): Promise<boolean> {
    // only use in code, use observable in template
    return !! await this.user$.pipe(take(1)).toPromise();
  }

  async signInWithGoogle() {
    const provider = new GoogleAuthProvider();
    provider.addScope('profile');
    provider.addScope('email');

    if (this.platform.is('desktop')) {
      await signInWithPopup(this.auth, provider);
      // const userInfo = result.user;
      // const credential = GoogleAuthProvider.credentialFromResult(result);
      // const token = credential.accessToken;
    } else {
      await signInWithRedirect(this.auth, provider);
      getRedirectResult(this.auth).then((result: any) => {
        if (result) {
          // const userInfo = result.user;
          // const credential = GoogleAuthProvider.credentialFromResult(result);
          // const token = credential.accessToken;
        }
      });

    }
  }

  logout() {
    signOut(this.auth).then(() => {
      this.router.navigate(['login']);
      window.location.reload();
    });
  }

}
